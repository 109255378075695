import React, { useEffect, useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import getVideoData from 'services/wistiaVideoService';
import useBreakpointView from 'utils/useBreakpointView';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WebsiteImage from 'components/WebsiteImage';
import WistiaVideo from 'components/Core/WistiaVideo';
import playButton from './images/play-button.svg';

import { StyledSectionLayout } from './styles';

const OverlayQuote = ({ entries }) => {
  const [isVideoPlaying, toggleIsVideoPlaying] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const video = getEntry('video-thumb-url', entries);
  const { inline, thumbnail, url } = video;
  const quote = getEntry('quote', entries);
  const playLabel = getEntry('label', entries);
  const logo = getEntry('logo', entries);
  const author = getEntry('author', entries);
  const isMobile = useBreakpointView(['xs', 'sm']);

  useEffect(() => {
    const contentfulThumbnail = thumbnail?.image?.file?.url;

    if (contentfulThumbnail) {
      setVideoThumbnail(contentfulThumbnail);
    } else {
      (async () => {
        const data = await getVideoData(url.trim());

        if (data) {
          setVideoThumbnail(data.thumbnail_url);
        }
      })();
    }
  }, []);

  return (
    <StyledSectionLayout backgroundColor="Darkest">
      <Row justify="center">
        <Col xs={24}>
          <div className="overlay">
            <div className="copy" onClick={() => toggleIsVideoPlaying(!isVideoPlaying)}>
              {quote && <ContentfulRichText className="min-md" richText={quote} />}
              <div className="play-label">
                {playLabel && <ContentfulRichText richText={playLabel} />}
                <WebsiteImage url={playButton} />
              </div>
              {logo && <WebsiteImage {...logo} className="logo" />}
              {author && <ContentfulRichText richText={author} />}
            </div>
          </div>
          {videoThumbnail && <WebsiteImage url={videoThumbnail} />}
          {isVideoPlaying && inline && url && !isMobile && (
            <WistiaVideo
              dataPosition="Marketing Pro - Overlay Quote"
              url={url}
              fullWidth
              inline
              autoPlay
              maxWidth="100%"
            />
          )}
          {isMobile && (
            <WistiaVideo
              dataPosition="Marketing Pro - Overlay Quote"
              url={url}
              fullWidth
              maxWidth="100%"
              playButtonOnly
            />
          )}
        </Col>
      </Row>
    </StyledSectionLayout>
  );
};

export default OverlayQuote;
