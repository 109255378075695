import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const StyledSectionLayout = styled(SectionLayout)`
  img {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--white);
    text-align: center;
  }

  .section-layout--inner-container {
    padding: 75px 38px !important;
  }

  .copy {
    max-width: 500px;
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .logo {
      max-width: 155px;
      margin: 20px auto 15px auto;
      display: flex;

      @media (max-width: 575px) {
        margin: 0 auto 5px auto;
      }
    }
  }

  .wistia-video--wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .play-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .rich-text--container {
      width: max-content;
    }

    p {
      margin: 0;
      text-decoration: underline;
    }

    img {
      width: 44px;
      margin-top: 8px;
    }
  }

  .overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767px) {
    .play-label {
      display: none;
    } 
  }
`;

export { StyledSectionLayout };
